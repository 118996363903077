.articles {
    background-color: $veryLightGray;
}

.article {
    &__content {}
    &__grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1.875rem;
        @include breakpoint-up(medium) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media(min-width: 1024px) {
            grid-template-columns: repeat(2, 3fr);
        }
        @include breakpoint-up(large) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    &__item {
        border-radius: .3125rem;
        overflow: hidden;
        background-color: $white;
        box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.15);
        transition: all 150ms ease-in-out;
        cursor: pointer;
        &:hover {
            transform: scale(1.05);
            cursor: pointer;
        }
    }
    &__image {
        height: 12.5rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
    }
    &__text {
        padding: 1.875rem 1.875rem 2.5rem 1.875rem;
        color: $grayishBlue;
        cursor: pointer;
        @include breakpoint-up(medium) {
            padding: 1.875rem 1.567rem;
        }
    }
    &__author {
        font-size: .625rem;
        margin-bottom: .75rem;
    }
    &__title {
        font-size: 1.0625rem;
        line-height: 1.2;
        color: #000;
        margin-bottom: .5rem;
        cursor: pointer;
    }
    &__description {
        font-size: .8125rem;
        cursor: pointer;
    }
    &__serviteur {
        text-align: center;
        @include breakpoint-up (medium) {
            text-align: right;
        }
        img {
            width: 300px;
            padding-bottom: 30px;
            @include breakpoint-up (medium) {
                padding: 0 0 30px 30px;
                float: right;
            }
        }
        h3 {
            font-weight: bold;
        }
        a {
            font-weight: bold;
            color: $grayishPink;
            border-bottom: 1px solid $grayishPink;
            cursor: pointer;
            &:hover,
            &:active {
                color: $grayishPink;
                cursor: pointer;
                border-bottom: none;
            }
        }
    }
}