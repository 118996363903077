#wrapper {
    background-color: #fff;
    transition: transform .3s ease-in;
    &.open {
        @include breakpoint-down(medium) {
            transform: translateX(-230px);
        }
    }
    .header_menu_mobile {
        position: fixed;
        top: 0;
        right: 0;
        width: 230px;
        // height: 100vh;
        z-index: 100;
        transform: translateX(100%);
        background-color: $darkGrayishPink;
        .header_menu {
            width: inherit;
            // height: 100%;
            margin-top: 40px;
            a {
                display: block;
                color: #fff;
                border-bottom: solid 1px $grayishOrange;
                width: inherit;
                text-align: center;
                height: 50px;
                line-height: 50px;
                border-right: 0;
                border-left: 0;
                cursor: pointer;
                text-transform: uppercase;
                &:first-of-type {
                    border-top: solid 1px $grayishOrange;
                }
                &:hover {
                    background-color: $grayishOrange;
                    color: #000;
                    cursor: pointer;
                }
            }
        }
    }
    .header {
        background-color: #fff;
        // Hamburger Open Styles
        &.open {}
        nav {
            position: relative;
            background-color: $white;
            padding-top: 1.0625rem;
            padding-bottom: 1.0625rem;
        }
        &__logo {
            img {
                width: 200px;
                // height: 1.375rem;
            }
        }
        &__toggle {
            // Mobile toggle
            >span {
                display: block;
                width: 26px;
                height: 2px;
                background-color: $darkBlue;
                transition: all 300ms ease-in-out;
                transform-origin: 3px 1px;
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }
        &__menu {
            position: absolute;
            width: calc(100% - 3rem);
            left: 50%;
            transform: translateX(-50%);
            background: $white;
            padding: 1.625rem;
            margin-top: 1.5rem;
            border-radius: 5px;
            z-index: 6;
            a {
                display: block;
                padding: .625rem;
                color: $darkBlue;
                text-align: center;
            }
        }
        &__links {
            a {
                position: relative;
                font-size: $font-sm;
                color: #000;
                transition: color 100ms ease-in-out;
                letter-spacing: 1.15px;
                cursor: pointer;
                &:not(:last-child) {
                    margin-right: 32px;
                }
                // &::before {
                //     content: "";
                //     display: block;
                //     position: absolute;
                //     height: 5px;
                //     left: 0;
                //     right: 0;
                //     bottom: -10px;
                //     background: linear-gradient(to right, $grayishPink, $darkGrayishPink);
                //     opacity: 0;
                //     transition: opacity 300ms ease-in-out;
                //     cursor: pointer;
                // }
                &:hover {
                    color: $grayishPink;
                    cursor: pointer;
                    &::before {
                        opacity: 1;
                    }
                }
            }
        }
    }
}