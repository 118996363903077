.footer {
    background-color: $darkGrayishPink;
    color: $white;
    padding: 2.5rem;
    text-align: center;
    .container {
        @include breakpoint-up(large) {
            display: grid;
            grid-template-columns: 2fr 1fr 1fr 3fr;
            grid-template-rows: 1fr auto;
            grid-template-areas: "logo links1 links2 cta""social links1 links2 copyright";
            justify-items: start;
            align-items: stretch;
            gap: 1rem;
        }
    }
    a {
        color: $white;
        letter-spacing: 1.5px;
    }
    &__logo {
        img {
            width: 200px;
        }
        display: inline-block;
        @include breakpoint-down(medium) {
            margin-bottom: 1.875rem;
        }
        @include breakpoint-up(large) {
            grid-area: logo;
        }
    }
    &__social {
        @include breakpoint-down(medium) {
            margin-bottom: 1.875rem;
        }
        @include breakpoint-up(large) {
            grid-area: social;
            align-self: end;
        }
        a {
            height: 1.25rem;
            display: inline-block;
            &:not(:last-child) {
                margin-right: 1rem;
            }
            svg path {
                transition: fill 150ms ease-in-out;
            }
            &:hover svg path {
                fill: $grayishOrange;
            }
        }
    }
    &__links {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: .9375rem;
        a {
            line-height: 2.25;
            transition: color 150ms ease-in-out;
            &:hover {
                color: $grayishOrange;
            }
        }
        @include breakpoint-up(large) {
            align-items: start;
            justify-content: space-between;
        }
        &.col1 {
            @include breakpoint-up(large) {
                grid-area: links1;
            }
        }
        &.col2 {
            @include breakpoint-down(medium) {
                margin-bottom: 1.875rem;
            }
            @include breakpoint-up(large) {
                grid-area: links2;
            }
        }
    }
    &__cta {
        @include breakpoint-up(large) {
            grid-area: cta;
            text-align: right;
            justify-self: end;
        }
        a.button {
            border: 2px solid $grayishPink;
            @include breakpoint-down(medium) {
                margin-bottom: 1.875rem;
            }
        }
    }
    &__copyright {
        font-size: .8125rem;
        color: $grayishBlue;
        @include breakpoint-up(large) {
            grid-area: copyright;
            align-self: end;
            justify-self: end;
        }
    }
}