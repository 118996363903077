.hero {
    background-color: $veryLightGray;
    position: relative;
    background-image: url('../../images/cmm_desktop.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    min-height: 17.5rem;
    @include breakpoint-up(medium) {
        min-height: 25rem;
        background-position: center center;
        background-size: cover;
    }
    @include breakpoint-up(large) {
        // flex: 3;
        // order: 2;
        // height: 100%;
        min-height: 30rem;
        // background-image: none;
        // background-image: url('/images/bg-intro-desktop.svg');
        // background-position: left center;
        // top: 10%;
    }
    &::before {
        @include breakpoint-up(large) {
            // content: "";
            // position: absolute;
            // height: 100%;
            // background-image: url('../../images/cmm_desktop.jpg');
            // background-position: center top;
            // width: 100%;
            // background-size: 90%;
            // background-repeat: no-repeat;
        }
    }
    .container {
        // background: linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(255, 255, 255, 0));
        height: 100%;
        width: 100%;
        min-height: 17.5rem;
        @include breakpoint-up(medium) {
            min-height: 25rem;
        }
        @include breakpoint-up(large) {
            display: flex;
            align-items: center;
            min-height: 30rem;
        }
    }
    &__image {}
    &__text {
        color: #fff;
        text-align: center;
        max-width: 80%;
        margin: 0 auto;
        h1 {
            color: #fff;
        }
        @include breakpoint-up(medium) {
            max-width: 60%;
        }
        @include breakpoint-up(large) {}
    }
}