// Colors
$grayishBlue: hsl(233, 8%, 62%);
$lightGrayishBlue: hsl(220, 16%, 96%);
$veryLightGray: hsl(0, 0%, 98%);
$white: hsl(0, 0%, 100%);
$grayishOrange: hsl(19, 33%, 71%);
$darkBlue: hsl(226, 70%, 52%);
$grayishPink: hsl(356, 53%, 62%);
$darkGrayishPurple: hsl(250, 21%, 44%);
$darkGrayishPink: hsl(284, 13%, 31%);
// Font sizes
$font-sm: 0.875rem; // 14px
$font-med: 1.125rem; // 18px
$font-xlg: 2rem // 32px