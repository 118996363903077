.feature {
    background-color: $lightGrayishBlue;
    text-align: center;
    @include breakpoint-up (medium) {
        text-align: left;
    }
    &__intro {
        margin-bottom: 1.75rem;
        @include breakpoint-up (large) {
            width: 70%;
        }
    }
    &__grid {
        @include breakpoint-up (medium) {
            display: flex;
            flex-wrap: wrap;
        }
    }
    &__item {
        padding: .9375rem .9375rem 50px;
        @include breakpoint-up (medium) {
            flex: 0 0 50%;
        }
        @include breakpoint-up (large) {
            flex: 1;
        }
        @media (min-width:1024px) {
            flex-basis: 300px;
        }
        @media (min-width:1247px) {
            flex-basis: 350px;
        }
    }
    &__icon {
        margin-bottom: 1.875rem;
        @include breakpoint-up (large) {
            margin-bottom: 2.75rem;
        }
    }
    &__title {
        font-size: 1.25rem;
        font-weight: 300;
        line-height: 1.15;
        color: hsl(356, 53%, 62%);
        margin-bottom: 1rem;
        @include breakpoint-up (large) {
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
        }
    }
    &__description {
        font-size: .875rem;
        line-height: 1.5rem;
    }
    &__btn {
        a {
            border: solid 1px hsl(284, 13%, 31%);
            border-radius: 5px;
            height: 50px;
            line-height: 50px;
            padding: 0 20px;
            display: inline-block;
            margin-top: 10px;
            color: hsl(284, 13%, 31%);
            cursor: pointer;
            &:hover {
                background-color: hsl(284, 13%, 31%);
                color: #fff;
                cursor: pointer;
            }
        }
    }
    &__serviteur {
        img {
            width: 300px;
            margin: 0 auto;
            padding-bottom: 30px;
            @include breakpoint-up (medium) {
                padding: 0 30px 30px 0;
                float: left;
            }
        }
    }
    &__emission {
        // background-color: hsl(356, 53%, 62%);
        background: url('../../images/_MG_1867.jpg') no-repeat center top;
        background-size: cover;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        .feature__content {
            padding: 25% 0;
        }
        @include breakpoint-up (medium) {
            // height: 80vh;
        }
        @include breakpoint-up (large) {
            // height: 100vh;
        }
        .feature__cta {
            height: 80px;
            line-height: 80px;
            padding: 0 50px;
        }
        a.button {
            border: 2px solid $grayishPink;
        }
    }
    &__calendar {
        margin-top: 25%;
        background-color: rgba(255, 255, 255, .95);
        box-shadow: 0px 0px 10px rgba(66, 6, 6, 0.5);
        padding: 10px 5px;
        border-radius: 5px;
        width: 300px;
        @include breakpoint-up (large) {
            width: 800px;
            padding: 25px 5px;
        }
    }
}

.calendar {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        display: inline-block;
        width: 32%;
        text-align: center;
        padding: 10px 0;
        @include breakpoint-up (large) {
            padding: 25px 5px;
        }
    }
    &__header {
        span {
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}