.has-fade {
  visibility: hidden;
}

@keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}
.fade-in {
  animation: fade-in 300ms ease-in-out forwards;
}

@keyframes fade-out {
  from {
    visibility: visible;
    opacity: 1;
  }
  99% {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}
.fade-out {
  animation: fade-out 300ms ease-in-out forwards;
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Public Sans", sans-serif;
  font-size: 0.875rem;
  letter-spacing: 1.5px;
  font-weight: 300;
  color: #000;
  line-height: 1.3;
  height: 100%;
  background-color: #544559;
  overflow-x: hidden;
}
@media (min-width: 64em) {
  body {
    font-size: 1.125rem;
  }
}
body.noscroll {
  overflow: hidden;
}

h1,
h2,
h3 {
  font-weight: 300;
  line-height: 1.15;
  color: #544559;
  margin-top: 0;
}

h1 {
  font-size: 1.875rem;
  margin-bottom: 1.5rem;
}
@media (min-width: 64em) {
  h1 {
    font-size: 2rem;
  }
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 1.5625rem;
}
@media (min-width: 64em) {
  h2 {
    font-size: 1.75rem;
    margin-bottom: 2.25rem;
  }
}

p {
  line-height: 1.5rem;
  margin-bottom: 2.25rem;
}

a,
a:hover,
a:visited {
  text-decoration: none;
}

.container {
  max-width: 90rem;
  margin: 0 auto;
}
.container--pall {
  padding: 4.375rem 1.5rem;
}
.container--py {
  padding-top: 4.375rem;
  padding-bottom: 4.375rem;
}
@media (min-width: 64em) {
  .container--py {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}
.container--px {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.container--pt {
  padding-top: 4.375rem;
}
@media (min-width: 64em) {
  .container--pt {
    padding-top: 6rem;
  }
}
.container--pr {
  padding-right: 1.5rem;
}
.container--pb {
  padding-bottom: 4.375rem;
}
@media (min-width: 64em) {
  .container--pb {
    padding-bottom: 6rem;
  }
}
.container--pl {
  padding-left: 1.5rem;
}

.flex {
  display: flex;
}
.flex-jc-sb {
  justify-content: space-between;
}
.flex-jc-c {
  justify-content: center;
}
.flex-ai-c {
  align-items: center;
}

button,
.button {
  position: relative;
  display: inline-block;
  padding: 0.5rem 0.75rem;
  letter-spacing: 1.5px;
  background: linear-gradient(to right, #d16b72, #544559);
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-weight: 400;
  font-size: 0.875rem;
  transition: opacity 300ms ease-in-out;
  overflow: hidden;
}
@media (min-width: ) {
  button,
.button {
    padding: 0.875rem 2.1875rem;
  }
}
button:hover,
.button:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.75);
}

@media (max-width: 63.9375em) {
  .hide-for-mobile {
    display: none;
  }
}

@media (min-width: 64em) {
  .hide-for-desktop {
    display: none;
  }
}

#wrapper {
  background-color: #fff;
  transition: transform 0.3s ease-in;
}
@media (max-width: 63.9375em) {
  #wrapper.open {
    transform: translateX(-230px);
  }
}
#wrapper .header_menu_mobile {
  position: fixed;
  top: 0;
  right: 0;
  width: 230px;
  z-index: 100;
  transform: translateX(100%);
  background-color: #544559;
}
#wrapper .header_menu_mobile .header_menu {
  width: inherit;
  margin-top: 40px;
}
#wrapper .header_menu_mobile .header_menu a {
  display: block;
  color: #fff;
  border-bottom: solid 1px #cdac9d;
  width: inherit;
  text-align: center;
  height: 50px;
  line-height: 50px;
  border-right: 0;
  border-left: 0;
  cursor: pointer;
  text-transform: uppercase;
}
#wrapper .header_menu_mobile .header_menu a:first-of-type {
  border-top: solid 1px #cdac9d;
}
#wrapper .header_menu_mobile .header_menu a:hover {
  background-color: #cdac9d;
  color: #000;
  cursor: pointer;
}
#wrapper .header {
  background-color: #fff;
}
#wrapper .header nav {
  position: relative;
  background-color: white;
  padding-top: 1.0625rem;
  padding-bottom: 1.0625rem;
}
#wrapper .header__logo img {
  width: 200px;
}
#wrapper .header__toggle > span {
  display: block;
  width: 26px;
  height: 2px;
  background-color: #2f57da;
  transition: all 300ms ease-in-out;
  transform-origin: 3px 1px;
}
#wrapper .header__toggle > span:not(:last-child) {
  margin-bottom: 5px;
}
#wrapper .header__menu {
  position: absolute;
  width: calc(100% - 3rem);
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 1.625rem;
  margin-top: 1.5rem;
  border-radius: 5px;
  z-index: 6;
}
#wrapper .header__menu a {
  display: block;
  padding: 0.625rem;
  color: #2f57da;
  text-align: center;
}
#wrapper .header__links a {
  position: relative;
  font-size: 0.875rem;
  color: #000;
  transition: color 100ms ease-in-out;
  letter-spacing: 1.15px;
  cursor: pointer;
}
#wrapper .header__links a:not(:last-child) {
  margin-right: 32px;
}
#wrapper .header__links a:hover {
  color: #d16b72;
  cursor: pointer;
}
#wrapper .header__links a:hover::before {
  opacity: 1;
}

.hero {
  background-color: #fafafa;
  position: relative;
  background-image: url("../../images/cmm_desktop.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  min-height: 17.5rem;
}
@media (min-width: 40em) {
  .hero {
    min-height: 25rem;
    background-position: center center;
    background-size: cover;
  }
}
@media (min-width: 64em) {
  .hero {
    min-height: 30rem;
  }
}
.hero .container {
  height: 100%;
  width: 100%;
  min-height: 17.5rem;
}
@media (min-width: 40em) {
  .hero .container {
    min-height: 25rem;
  }
}
@media (min-width: 64em) {
  .hero .container {
    display: flex;
    align-items: center;
    min-height: 30rem;
  }
}
.hero__text {
  color: #fff;
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
}
.hero__text h1 {
  color: #fff;
}
@media (min-width: 40em) {
  .hero__text {
    max-width: 60%;
  }
}
.feature {
  background-color: #f3f4f6;
  text-align: center;
}
@media (min-width: 40em) {
  .feature {
    text-align: left;
  }
}
.feature__intro {
  margin-bottom: 1.75rem;
}
@media (min-width: 64em) {
  .feature__intro {
    width: 70%;
  }
}
@media (min-width: 40em) {
  .feature__grid {
    display: flex;
    flex-wrap: wrap;
  }
}
.feature__item {
  padding: 0.9375rem 0.9375rem 50px;
}
@media (min-width: 40em) {
  .feature__item {
    flex: 0 0 50%;
  }
}
@media (min-width: 64em) {
  .feature__item {
    flex: 1;
  }
}
@media (min-width: 1024px) {
  .feature__item {
    flex-basis: 300px;
  }
}
@media (min-width: 1247px) {
  .feature__item {
    flex-basis: 350px;
  }
}
.feature__icon {
  margin-bottom: 1.875rem;
}
@media (min-width: 64em) {
  .feature__icon {
    margin-bottom: 2.75rem;
  }
}
.feature__title {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.15;
  color: #d16b72;
  margin-bottom: 1rem;
}
@media (min-width: 64em) {
  .feature__title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
.feature__description {
  font-size: 0.875rem;
  line-height: 1.5rem;
}
.feature__btn a {
  border: solid 1px #544559;
  border-radius: 5px;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  display: inline-block;
  margin-top: 10px;
  color: #544559;
  cursor: pointer;
}
.feature__btn a:hover {
  background-color: #544559;
  color: #fff;
  cursor: pointer;
}
.feature__serviteur img {
  width: 300px;
  margin: 0 auto;
  padding-bottom: 30px;
}
@media (min-width: 40em) {
  .feature__serviteur img {
    padding: 0 30px 30px 0;
    float: left;
  }
}
.feature__emission {
  background: url("../../images/_MG_1867.jpg") no-repeat center top;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.feature__emission .feature__content {
  padding: 25% 0;
}
.feature__emission .feature__cta {
  height: 80px;
  line-height: 80px;
  padding: 0 50px;
}
.feature__emission a.button {
  border: 2px solid #d16b72;
}
.feature__calendar {
  margin-top: 25%;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 0px 10px rgba(66, 6, 6, 0.5);
  padding: 10px 5px;
  border-radius: 5px;
  width: 300px;
}
@media (min-width: 64em) {
  .feature__calendar {
    width: 800px;
    padding: 25px 5px;
  }
}

.calendar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendar span {
  display: inline-block;
  width: 32%;
  text-align: center;
  padding: 10px 0;
}
@media (min-width: 64em) {
  .calendar span {
    padding: 25px 5px;
  }
}
.calendar__header span {
  font-weight: bold;
  text-transform: uppercase;
}

.articles {
  background-color: #fafafa;
}

.article__grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
}
@media (min-width: 40em) {
  .article__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1024px) {
  .article__grid {
    grid-template-columns: repeat(2, 3fr);
  }
}
@media (min-width: 64em) {
  .article__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
.article__item {
  border-radius: 0.3125rem;
  overflow: hidden;
  background-color: white;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.15);
  transition: all 150ms ease-in-out;
  cursor: pointer;
}
.article__item:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.article__image {
  height: 12.5rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}
.article__text {
  padding: 1.875rem 1.875rem 2.5rem 1.875rem;
  color: #9698a6;
  cursor: pointer;
}
@media (min-width: 40em) {
  .article__text {
    padding: 1.875rem 1.567rem;
  }
}
.article__author {
  font-size: 0.625rem;
  margin-bottom: 0.75rem;
}
.article__title {
  font-size: 1.0625rem;
  line-height: 1.2;
  color: #000;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.article__description {
  font-size: 0.8125rem;
  cursor: pointer;
}
.article__serviteur {
  text-align: center;
}
@media (min-width: 40em) {
  .article__serviteur {
    text-align: right;
  }
}
.article__serviteur img {
  width: 300px;
  padding-bottom: 30px;
}
@media (min-width: 40em) {
  .article__serviteur img {
    padding: 0 0 30px 30px;
    float: right;
  }
}
.article__serviteur h3 {
  font-weight: bold;
}
.article__serviteur a {
  font-weight: bold;
  color: #d16b72;
  border-bottom: 1px solid #d16b72;
  cursor: pointer;
}
.article__serviteur a:hover, .article__serviteur a:active {
  color: #d16b72;
  cursor: pointer;
  border-bottom: none;
}

#wrapper {
  background-color: #fff;
  height: 100%;
}

@media (min-width: 1024px) {
  form {
    max-width: 800px;
  }
}
.form-group {
  margin-bottom: 1.5em;
  transition: all 0.3s;
}

.form-label {
  font-size: 0.75em;
  color: var(--font-color);
  display: block;
  opacity: 0;
  transition: all 0.3s;
  transform: translateX(-50px);
}

.form-control {
  box-shadow: none;
  border-radius: 0;
  border-color: #ccc;
  border-style: none none solid none;
  width: 100%;
  font-size: 1em;
  transition: all 0.6s;
  background: transparent;
}

.form-control::placeholder {
  color: #aaa;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--font-hover-color);
  outline: none;
}

.form-group:focus-within {
  transform: scale(1.015, 1.015);
}

.form-control:invalid:focus {
  border-color: #d16b72;
}

.form-control:valid:focus {
  border-color: green;
}

.btn {
  background: 0 0 #fff;
  border: 1px solid #aaa;
  border-radius: 3px;
  color: var(--font-color);
  font-size: 1em;
  padding: 10 50px;
  text-transform: uppercase;
  transition: background-color 0.25s ease-in;
  cursor: pointer;
}

.btn:hover {
  box-shadow: none;
  background-color: #000;
  color: white;
  cursor: pointer;
}

textarea {
  resize: none;
}

.focused > .form-label {
  opacity: 1;
  transform: translateX(0px);
}

.form-invalid {
  outline: 2px solid red !important;
}

.footer {
  background-color: #544559;
  color: white;
  padding: 2.5rem;
  text-align: center;
}
@media (min-width: 64em) {
  .footer .container {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 3fr;
    grid-template-rows: 1fr auto;
    grid-template-areas: "logo links1 links2 cta" "social links1 links2 copyright";
    justify-items: start;
    align-items: stretch;
    gap: 1rem;
  }
}
.footer a {
  color: white;
  letter-spacing: 1.5px;
}
.footer__logo {
  display: inline-block;
}
.footer__logo img {
  width: 200px;
}
@media (max-width: 63.9375em) {
  .footer__logo {
    margin-bottom: 1.875rem;
  }
}
@media (min-width: 64em) {
  .footer__logo {
    grid-area: logo;
  }
}
@media (max-width: 63.9375em) {
  .footer__social {
    margin-bottom: 1.875rem;
  }
}
@media (min-width: 64em) {
  .footer__social {
    grid-area: social;
    align-self: end;
  }
}
.footer__social a {
  height: 1.25rem;
  display: inline-block;
}
.footer__social a:not(:last-child) {
  margin-right: 1rem;
}
.footer__social a svg path {
  transition: fill 150ms ease-in-out;
}
.footer__social a:hover svg path {
  fill: #cdac9d;
}
.footer__links {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.9375rem;
}
.footer__links a {
  line-height: 2.25;
  transition: color 150ms ease-in-out;
}
.footer__links a:hover {
  color: #cdac9d;
}
@media (min-width: 64em) {
  .footer__links {
    align-items: start;
    justify-content: space-between;
  }
}
@media (min-width: 64em) {
  .footer__links.col1 {
    grid-area: links1;
  }
}
@media (max-width: 63.9375em) {
  .footer__links.col2 {
    margin-bottom: 1.875rem;
  }
}
@media (min-width: 64em) {
  .footer__links.col2 {
    grid-area: links2;
  }
}
@media (min-width: 64em) {
  .footer__cta {
    grid-area: cta;
    text-align: right;
    justify-self: end;
  }
}
.footer__cta a.button {
  border: 2px solid #d16b72;
}
@media (max-width: 63.9375em) {
  .footer__cta a.button {
    margin-bottom: 1.875rem;
  }
}
.footer__copyright {
  font-size: 0.8125rem;
  color: #9698a6;
}
@media (min-width: 64em) {
  .footer__copyright {
    grid-area: copyright;
    align-self: end;
    justify-self: end;
  }
}