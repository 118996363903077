html {
    font-size: 100%;
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Public Sans', sans-serif;
    font-size: $font-sm;
    letter-spacing: 1.5px;
    font-weight: 300;
    color: #000;
    line-height: 1.3;
    height: 100%;
    background-color: $darkGrayishPink;
    overflow-x: hidden;
    @include breakpoint-up(large) {
        font-size: $font-med;
    }
    &.noscroll {
        overflow: hidden;
    }
}

h1,
h2,
h3 {
    font-weight: 300;
    line-height: 1.15;
    color: $darkGrayishPink;
    margin-top: 0;
}

h1 {
    font-size: 1.875rem;
    margin-bottom: 1.5rem;
    @include breakpoint-up(large) {
        font-size: 2rem;
    }
}

h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5625rem;
    @include breakpoint-up (large) {
        font-size: 1.75rem;
        margin-bottom: 2.25rem;
    }
}

p {
    line-height: 1.5rem;
    margin-bottom: 2.25rem;
}

a,
a:hover,
a:visited {
    text-decoration: none;
}

// Spacing
.container {
    max-width: 90rem; // 1110px
    margin: 0 auto;
    &--pall {
        padding: 4.375rem 1.5rem;
    }
    &--py {
        padding-top: 4.375rem;
        padding-bottom: 4.375rem;
        @include breakpoint-up (large) {
            padding-top: 6rem;
            padding-bottom: 6rem;
        }
    }
    &--px {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
    &--pt {
        padding-top: 4.375rem;
        @include breakpoint-up (large) {
            padding-top: 6rem;
        }
    }
    &--pr {
        padding-right: 1.5rem;
    }
    &--pb {
        padding-bottom: 4.375rem;
        @include breakpoint-up (large) {
            padding-bottom: 6rem;
        }
    }
    &--pl {
        padding-left: 1.5rem;
    }
}

// Flexbox
.flex {
    display: flex;
    &-jc-sb {
        justify-content: space-between;
    }
    &-jc-c {
        justify-content: center;
    }
    &-ai-c {
        align-items: center;
    }
}

// Buttons
button,
.button {
    position: relative;
    display: inline-block;
    padding: .50rem .75rem;
    letter-spacing: 1.5px;
    background: linear-gradient(to right, $grayishPink, $darkGrayishPink);
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    color: $white;
    font-weight: 400;
    font-size: $font-sm;
    transition: opacity 300ms ease-in-out;
    overflow: hidden;
    @include breakpoint-up(small) {
        padding: .875rem 2.1875rem;
    }
    &:hover {
        box-shadow: 0px 3px 8px rgba(0, 0, 0, .75);
    }
    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     left: 0;
    //     background-color: rgba(255, 255, 255, 0.25);
    //     opacity: 0;
    //     transition: opacity 150ms ease-in-out;
    // }
    // &:hover::before {
    //     opacity: 1;
    // }
}

// Visibility
.hide-for-mobile {
    // hide for tablet and mobile
    @include breakpoint-down(medium) {
        display: none;
    }
}

.hide-for-desktop {
    // hide for desktop viewport widths
    @include breakpoint-up(large) {
        display: none;
    }
}