#wrapper {
    background-color: #fff;
    height: 100%;
}

@media (min-width: 1024px) {
    form {
        max-width: 800px;
    }
}

.form-group {
    margin-bottom: 1.5em;
    transition: all .3s;
}

.form-label {
    font-size: .75em;
    color: var(--font-color);
    display: block;
    opacity: 0;
    transition: all .3s;
    transform: translateX(-50px);
}

.form-control {
    box-shadow: none;
    border-radius: 0;
    border-color: #ccc;
    border-style: none none solid none;
    width: 100%;
    font-size: 1em;
    transition: all .6s;
    background: transparent;
}

.form-control::placeholder {
    color: #aaa;
}

.form-control:focus {
    box-shadow: none;
    border-color: var(--font-hover-color);
    outline: none;
}

.form-group:focus-within {
    transform: scale(1.015, 1.015);
}

.form-control:invalid:focus {
    border-color: hsl(356, 53%, 62%);
}

.form-control:valid:focus {
    border-color: green;
}

.btn {
    background: 0 0 #fff;
    border: 1px solid #aaa;
    border-radius: 3px;
    color: var(--font-color);
    font-size: 1em;
    padding: 10 50px;
    text-transform: uppercase;
    transition: background-color .25s ease-in;
    cursor: pointer;
}

.btn:hover {
    box-shadow: none;
    background-color: #000;
    color: white;
    cursor: pointer;
}

textarea {
    resize: none;
}

.focused>.form-label {
    opacity: 1;
    transform: translateX(0px);
}

.form-invalid {
    outline: 2px solid red !important;
}